import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import Amplify, { Auth } from 'aws-amplify';
import { amplifyConfig } from '../config';

import axiosInstance from '../lib/axiosql';
// import { organizations } from 'src/__mocks__/mockConstants';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { GRAPHQL_HEADER, GRAPHQL_URL } from '../../src/constants';
//import { updateOrganizationInfo } from '../../src/graphql/mutations';
import { getUserWithOrganization } from '../../src/graphql/queries_batch';
// import useProbes from 'src/hooks/useProbes';

Amplify.configure(amplifyConfig);

const getUserWithOrganizationQL = gql(getUserWithOrganization)

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state) => ({ ...state }),
  VERIFY_CODE: (state) => ({ ...state }),
  RESEND_CODE: (state) => ({ ...state }),
  PASSWORD_RECOVERY: (state) => ({ ...state }),
  PASSWORD_RESET: (state) => ({ ...state }),
  PASSWORD_CHANGE: (state) => ({ ...state})
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

const AuthContext = createContext({
  ...initialState,
  platform: 'Amplify',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  verifyCode: () => Promise.resolve(),
  resendCode: () => Promise.resolve(),
  passwordRecovery: () => Promise.resolve(),
  passwordReset: () => Promise.resolve(),
  passwordChange: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  //const { restoreProbes } = useProbes();

  useEffect(() => {
    const initialize = async () => {
      try {

        //const probes = await restoreProbes();

        const user = await Auth.currentAuthenticatedUser();

        const response = await axiosInstance.post(
          GRAPHQL_URL,
            {
              query: print(getUserWithOrganizationQL),
            },
            GRAPHQL_HEADER
        );
        
        const organization = response.data.data.getOrganizationInfo;
        const dbuser = response.data.data.getUserInfo;

        console.log("[AmplifyContext].AuthProvider init useEffect user,organization, dbuser", user, organization, dbuser);

        // Here you should extract the complete user profile to make it
        // available in your entire app.
        // The auth state only provides basic information.

        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user: {
              id: user.attributes.sub,
              username: user.username,
              organization: user.attributes["custom:org"].split("#")[1],
              organizationName: organization.name,
              organizationEmail: organization.email,
              usr: user.attributes["custom:usr"],
              avatar: dbuser.avatar,
              // avatar: null,
              email: user.attributes.email,
              name: dbuser.name,
              role: dbuser.role,
              plan: 'Free Trial',
              attributes: user.attributes,
              idToken: user.signInUserSession.idToken,
              accessToken: user.signInUserSession.accessToken,
              organizationPhone: organization.phone,
              stripeCustomerId: organization.stripeCustId
            }
          }
        });
      } catch (error) {
        console.error("[AmplifyContext] initialize error", error);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };
    
    initialize();
  }, []);
  
  const login = async (username, password) => {

    console.log("[AmplifyContext].login username, password", username, password);
    const user = await Auth.signIn(username, password);
    
    console.log("[AmplifyContext].login user", user);
    
    if (user.challengeName) {
      console.error(`Unable to login, because challenge "${user.challengeName}" is mandated and we did not handle this case.`);
      return;
    }
    const response = await axiosInstance.post(
      GRAPHQL_URL,
        {
          query: print(getUserWithOrganizationQL),
        },
        GRAPHQL_HEADER
    );
    
    const organization = response.data.data.getOrganizationInfo;
    const dbuser = response.data.data.getUserInfo;

    console.log("[AmplifyContext].AuthProvider.login user,organization, dbuser", user, organization, dbuser);

    dispatch({
      type: 'LOGIN',
      payload: {
        user: {
          id: user.attributes.sub,
          username: user.username,
          organization: user.attributes["custom:org"].split("#")[1],
          organizationName: organization.name,
          organizationEmail: organization.email,
          usr: user.attributes["custom:usr"],
          avatar: dbuser.avatar,
          email: user.attributes.email,
          name: dbuser.name,
          role: dbuser.role,
          plan: 'Free Trial',
          attributes: user.attributes,
          idToken: user.signInUserSession.idToken,
          accessToken: user.signInUserSession.accessToken,
          organizationPhone: organization.phone,
          stripeCustomerId: organization.stripeCustId
        }
      }
    });

  };

  const logout = async () => {
    await Auth.signOut();
    dispatch({
      type: 'LOGOUT'
    });
  };

  const register = async (username, email, password, profile) => {
    await Auth.signUp({
      username,
      password,
      attributes: { email, profile }
    });
    dispatch({
      type: 'REGISTER'
    });
  };

  const verifyCode = async (username, code) => {
    await Auth.confirmSignUp(username, code);
    dispatch({
      type: 'VERIFY_CODE'
    });
  };

  const resendCode = async (username) => {
    await Auth.resendSignUp(username);
    dispatch({
      type: 'RESEND_CODE'
    });
  };

  const passwordRecovery = async (username) => {
    await Auth.forgotPassword(username);
    dispatch({
      type: 'PASSWORD_RECOVERY'
    });
  };

  const passwordReset = async (username, code, newPassword) => {
    await Auth.forgotPasswordSubmit(username, code, newPassword);
    dispatch({
      type: 'PASSWORD_RESET'
    });
  };

  const passwordChange = async(oldPassword, newPassword) => {
    console.log("[AmplifContext].passwordChange state.user, state", state.user, state);

    await Auth.currentAuthenticatedUser()
    .then(user => {
        return Auth.changePassword(user, oldPassword, newPassword);
    })
    .then(data => console.log(data));
    
    
    dispatch({
      type: 'PASSWORD_CHANGE'
    });
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'Amplify',
        login,
        logout,
        register,
        verifyCode,
        resendCode,
        passwordRecovery,
        passwordReset,
        passwordChange
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
