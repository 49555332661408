
let getMonitor = `
    getMonitor(taskId: $taskId) {
      items {
        P
        S
        P1
        P2
        params
        locations
        updated
        status
        monitorType
        probesStatus
        notifications
      }
    }
`;

let getUserInfo = `
    getUserInfo {
      P
      S
      avatar
      email
      name
      phone
      isOnline
      ACT
      role
    }
`;


export const getMonRepWithMonitor = /* GraphQL */ `
  query GetMonRepWithMonitor($id: Int!, $start: String!, $end: String!, $taskId: String!) {
    
    getMonRep(id: $id, start: $start, end: $end) {
      smrSuccessCount
      smrFailCount
      smrFailedLocations
      smrLastMeasures
      perfData
    }
    ${getMonitor}

  }
`;


export const getLHMonRepWithMonitor = /* GraphQL */  `
  query GetLHMonRepWithMonitor($id: Int!, $start: String!, $end: String!, $taskId: String!) {
    
    getLHMonRep(id: $id, start: $start, end: $end) {
      items {
        P
        S
        P1
        res
        rdetails
        fileUrl
        rimpacts
        rscores
      }
    }
    ${getMonitor}

  }
`;


export const getAdMonRepWithMonitor = /* GraphQL */  `
query GetAdMonRepWithMonitor($id: Int!, $start: String!, $end: String!, $taskId: String!) {

    getAdMonRep(id: $id, start: $start, end: $end) {
      items {
        P
        S
        P1
        res
        diag
        rtt
        latency
        metrics
        harfile
        rsum
        rnbyd
      }
    }
    ${getMonitor}

  }
`;


export const getUserWithOrganization = /* GraphQL */ `
  query GetUserWithOrganization {

    ${getUserInfo}

    getOrganizationInfo {
      P
      S
      name
      phone
      country
      state
      city
      ACT
      email
      isEmailVerified
      stripeCustId
    }

  }
`;


export const getUserWithActivities = /* GraphQL */ `
  query GetUserWithActivities {

    ${getUserInfo}

    getCognitoUserAuthEvents {
      ms
      cd
      st
      data
    }
  
  }
`;