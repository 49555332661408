export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE'
};

export const GRAPHQL_URL = process.env.REACT_APP_AWS_APPSYNC_GRAPHQLENDPOINT;
export const GRAPHQL_HEADER = {
  headers: {
    'Content-Type': 'application/json'
  }
};
