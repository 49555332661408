/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUserInfo = /* GraphQL */ `
  query GetUserInfo {
    getUserInfo {
      P
      S
      avatar
      email
      name
      phone
      isOnline
      ACT
      role
    }
  }
`;
export const getOrganizationInfo = /* GraphQL */ `
  query GetOrganizationInfo {
    getOrganizationInfo {
      P
      S
      name
      phone
      country
      state
      city
      ACT
      email
      isEmailVerified
      stripeCustId
    }
  }
`;
export const getMonitorReport = /* GraphQL */ `
  query GetMonitorReport($id: Int!, $start: String!, $end: String!) {
    getMonitorReport(id: $id, start: $start, end: $end) {
      items {
        P
        S
        prb
        res
      }
    }
  }
`;
export const getMonRep = /* GraphQL */ `
  query GetMonRep($id: Int!, $start: String!, $end: String!) {
    getMonRep(id: $id, start: $start, end: $end) {
      smrSuccessCount
      smrFailCount
      smrFailedLocations
      smrLastMeasures
      perfData
    }
  }
`;
export const getQuota = /* GraphQL */ `
  query GetQuota {
    getQuota {
      P
      S
      currentPackage
    }
  }
`;
export const getBilling = /* GraphQL */ `
  query GetBilling {
    getBilling {
      P
      S
      currentPackage
      subscription_tier
    }
  }
`;
export const getMonitors = /* GraphQL */ `
  query GetMonitors {
    getMonitors {
      items {
        P
        S
        P1
        P2
        params
        locations
        updated
        status
        monitorType
        probesStatus
        notifications
        SslCheck
        SbGoogle
        SbYandex
        IpBlArray
        DomainBlArray
        alarmon
      }
    }
  }
`;
export const getMonitor = /* GraphQL */ `
  query GetMonitor($taskId: String!) {
    getMonitor(taskId: $taskId) {
      items {
        P
        S
        P1
        P2
        params
        locations
        updated
        status
        monitorType
        probesStatus
        notifications
        SslCheck
        SbGoogle
        SbYandex
        IpBlArray
        DomainBlArray
        alarmon
      }
    }
  }
`;
export const getLHMonRep = /* GraphQL */ `
  query GetLHMonRep($id: Int!, $start: String!, $end: String!) {
    getLHMonRep(id: $id, start: $start, end: $end) {
      items {
        P
        S
        P1
        res
        rdetails
        fileUrl
        rimpacts
        rscores
      }
    }
  }
`;
export const getProbes = /* GraphQL */ `
  query GetProbes {
    getProbes {
      items {
        P
        S
        regions
      }
    }
  }
`;
export const getAdMonRep = /* GraphQL */ `
  query GetAdMonRep($id: Int!, $start: String!, $end: String!) {
    getAdMonRep(id: $id, start: $start, end: $end) {
      items {
        P
        S
        P1
        res
        diag
        rtt
        latency
        metrics
        harfile
        rsum
        rnbyd
      }
    }
  }
`;
export const getOrgUsers = /* GraphQL */ `
  query GetOrgUsers {
    getOrgUsers {
      items {
        P
        S
        avatar
        email
        name
        phone
        isOnline
        ACT
        role
      }
    }
  }
`;
export const getOrgUser = /* GraphQL */ `
  query GetOrgUser($email: AWSEmail) {
    getOrgUser(email: $email) {
      P
      S
      avatar
      email
      name
      phone
      isOnline
      ACT
      role
    }
  }
`;
export const getCognitoUserAuthEvents = /* GraphQL */ `
  query GetCognitoUserAuthEvents {
    getCognitoUserAuthEvents {
      ms
      cd
      st
      data
    }
  }
`;
export const getCheck = /* GraphQL */ `
  query GetCheck($checkId: String!) {
    getCheck(checkId: $checkId) {
      items {
        P
        S
        P1
        P2
        params
        locations
        updated
        status
        monitorType
        SslCheck
        SbGoogle
        SbYandex
        IpBlArray
        DomainBlArray
      }
    }
  }
`;
