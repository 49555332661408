import { createSlice } from '@reduxjs/toolkit';
import { updateObject } from 'src/utils/utility';

const initialState = {

  // steps =>    0  --<select type>-- 1 --<monitor properties form>-- 2 --<description>-- 3
  // 0: initial
  // 1: type selected
  // 2: properties set
  // 3: completed
  //step: 0,

  //step 1 : type of monitor
  type: null,
  //step 2 : monitor properties
  params: {},
  //step 3 : description
  description: null,

};

const slice = createSlice({
  name: 'monitor',
  initialState,
  reducers: {

    nextStep(state, action) {
      const { step, stepvalue } = action.payload;
      
      if (step === 1){
        state.type = stepvalue;
      }else if ( step === 2){
        state.params = { ...stepvalue };
      }else if ( step === 3){
        state.description = stepvalue;
      }else {
        console.error("[monitor.js] Invalid step value on nextStep action", step);
      }
      console.log("monitor.js : nextstep state", state);
    },
    
    backStep(state, action) {
      const { step } = action.payload;
      
      if (step === 0) {
        //state = updateObject(state, initialState);
        state.params = {};
      } else if (step === 1) {
        //state.params = {};
        state.description = null;
      } else if (step === 2) {
        //state.description = null;
      }else {
        console.error("[monitor.js] Invalid step value on backStep action", step);
      }
      console.log("monitor.js : backstep state", state);
    },

    reset(state, action) {
      console.log("monitor.js: pre reset state", state);
      state = updateObject( updateObject(state,initialState), {description:"reset"});
      console.log("monitor.js : reset state", state);
    }

  }
});

export const { reducer } = slice;

export const nextStep = (step, stepvalue) => async ( dispatch ) => {
  dispatch(slice.actions.nextStep({ step, stepvalue }));
};

export const backStep = (step) => async ( dispatch ) => {
  dispatch(slice.actions.backStep({ step }));
};

export const reset = () => async ( dispatch ) => {
  dispatch(slice.actions.reset());
}

export default slice;