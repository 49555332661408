import { createSlice } from '@reduxjs/toolkit';
import { updateObject } from 'src/utils/utility';

const initialState = {

  // steps =>    0  --<select type>-- 1 --<check properties form> -- 2
  // 0: initial
  // 1: type selected
  // 2: properties set and completed

  //step: 0,
  //step 1 : type of check
  type: null,
  //step 2 : check properties
  
  
};

const slice = createSlice({
  name: 'check',
  initialState,
  reducers: {

    nextStep(state, action) {
      const { step, stepvalue } = action.payload;
      
      if (step === 1){
        state.type = stepvalue;
      }else if ( step === 2){
        //state.params = { ...stepvalue };
      }else {
        console.error("[check.js] Invalid step value on nextStep action", step);
      }
      console.log("check.js : nextstep state", state);
    },
    
    backStep(state, action) {
      const { step } = action.payload;
      
      if (step === 0) {
        //state = updateObject(state, initialState);
        state.params = {};
      } else if (step === 1) {
        //state.params = {};
      }else {
        console.error("[check.js] Invalid step value on backStep action", step);
      }
      console.log("check.js : backstep state", state);
    },

    reset(state, action) {
      console.log("check.js: pre reset state", state);
      state = updateObject(state,initialState);
      console.log("check.js : reset state", state);
    }

  }
});

export const { reducer } = slice;

export const nextStep = (step, stepvalue) => async ( dispatch ) => {
  dispatch(slice.actions.nextStep({ step, stepvalue }));
};

export const backStep = (step) => async ( dispatch ) => {
  dispatch(slice.actions.backStep({ step }));
};

export const reset = () => async ( dispatch ) => {
  dispatch(slice.actions.reset());
}

export default slice;