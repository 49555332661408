import { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import axiosInstance from '../lib/axiosql';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { GRAPHQL_HEADER, GRAPHQL_URL } from '../../src/constants';
import { getProbes } from 'src/graphql/queries';
import useAuth from 'src/hooks/useAuth';

const getProbesQL = gql(getProbes);

const initialProbes = {
  probes: []
};

export const restoreProbes = async () => { 

  try {
    
    //TODO Read these probes via Appsync request getProbes
    const response = await axiosInstance.post(
      GRAPHQL_URL, 
      {
          query: print(getProbesQL)
      },
      GRAPHQL_HEADER
    );
    //console.log("[ProbesContext] restoreProbes.getProbes response", response);

    const items = response.data.data.getProbes.items;

    const probes = items.map( p => ({
      'S': p.S,
      'regions': JSON.parse(p.regions)
    }));

    //console.log("[ProbesContext] Parsed probes", probes);

    return probes;


  } catch (err) {
    console.error(err);
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }

  return [];
};


const ProbesContext = createContext({
  probes: initialProbes,
  restoreProbes: () => Promise.resolve()
});

export const ProbesProvider = (props) => {
  const { children } = props;
  const [probes, setProbes] = useState(initialProbes);
  const { user } = useAuth();

  useEffect( () => {

    const fetchProbes = async () => {
      console.log("[ProbesProvider] useEffect");
      
      const restoredProbes = await restoreProbes();
      console.log("[ProbesProvider] useEffect restoredProbes", restoredProbes);
      
      if (restoredProbes) {
        setProbes(restoredProbes);
      }
    }

    fetchProbes();
    
  }, [user]);
  
  return (
    <ProbesContext.Provider
      value={{
        probes,
        restoreProbes
      }}
    >
      {children}
    </ProbesContext.Provider>
  );
};

ProbesProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const ProbesConsumer = ProbesContext.Consumer;

export default ProbesContext;