import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/OCGuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/OCMainLayout';
//import AdMonitorDetails from './pages/dashboard/AdMonitorDetails';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));
const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')));

// Dashboard pages

const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));
const Profile = Loadable(lazy(() => import('./pages/dashboard/Profile')));

const UserList = Loadable(lazy(() => import('./pages/dashboard/UserList')));
const UserCreate = Loadable(lazy(() => import('./pages/dashboard/UserCreate')));
const UserDetails = Loadable(lazy(() => import('./pages/dashboard/UserDetails')));
const UserEdit = Loadable(lazy(() => import('./pages/dashboard/UserEdit')));

// Error pages

const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// Monitors pages
//const MonitorBrowse =Loadable(lazy(() => import('./pages/dashboard/MonitorBrowse')));
const MonitorCreate =Loadable(lazy(() => import('./pages/dashboard/MonitorCreate')));
const MonitorEdit =Loadable(lazy(() => import('./pages/dashboard/MonitorEdit')));
const MonitorDetails =Loadable(lazy(() => import('./pages/dashboard/MonitorDetails')));
const LHMonitorDetails =Loadable(lazy(() => import('./pages/dashboard/LHMonitorDetails')));
const AdMonitorDetails = Loadable(lazy(() => import('./pages/dashboard/AdMonitorDetails')));

// Check pages
const CheckCreate = Loadable(lazy(() => import('./pages/dashboard/CheckCreate')));
const CheckDetails = Loadable( lazy(()=> import('./pages/dashboard/CheckDetails')));
const CheckEdit = Loadable( lazy(()=> import('./pages/dashboard/CheckEdit')));

// Other pages

const Checkout = Loadable(lazy(() => import('./pages/Checkout')));
const Contact = Loadable(lazy(() => import('./pages/Contact')));
const Home = Loadable(lazy(() => import('./pages/Home')));
const Pricing = Loadable(lazy(() => import('./pages/Pricing')));
const Payments = Loadable(lazy(() => import('./pages/Payments')));
const Subscriptions = Loadable(lazy(() => import('./pages/Subscriptions')));

const routes = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard to="/">
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'login-unguarded',
        element: <Login />
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />
      },
      {
        path: 'password-reset',
        element: <PasswordReset />
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        )
      },
      {
        path: 'register-unguarded',
        element: <Register />
      },
      {
        path: 'verify-code',
        element: <VerifyCode />
      }
    ]
  },
  /* {
    path: 'blog',
    element: <BlogLayout />,
    children: [
      {
        path: '/',
        element: <BlogPostList />
      },
      {
        path: 'new',
        element: <BlogPostCreate />
      },
      {
        path: ':postId',
        element: <BlogPostDetails />
      }
    ]
  }, */
  {
    path: 'contact',
    element: <Contact />
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <Overview />
      },
      {
        path: 'account',
        element: <Account />
      },
      {
        path: 'profile',
        element: <Profile />
      },
      {
        path: 'users',
        children: [
          {
            path: '/',
            element: <UserList />
          },
          {
            path: ':userId',
            element: <UserDetails />
          },
          {
            path: ':userId/edit',
            element: <UserEdit />
          },
          {
            path: 'new',
            element: <UserCreate />
          }
        ]
      },
      {
        path: 'payment',
        children: [
          {
            path: 'pricing',
            element: <Pricing />
          },
          {
            path: 'checkout',
            element: <Checkout />
          },
          {
            path: 'payments',
            element: <Payments />
          },
          {
            path: 'subscriptions',
            element: <Subscriptions />
          }
        ]
      },
      {
        path: 'monitors',
        children: [
          {
            path: 'new',
            element: <MonitorCreate />
          },
          {
            path: 'lhreport/:taskId',
            element: <LHMonitorDetails />
          },
          {
            path: 'advanced/:taskId',
            element: <AdMonitorDetails />
          },
          {
            path: ':taskId',
            element: <MonitorDetails />
          },
          {
            path: ':taskId/edit',
            element: <MonitorEdit />
          }
        ]
      },
      {
        path: 'checks',
        children: [
          {
            path: 'new',
            element: <CheckCreate />
          },
          {
            path: 'sslcheck/:taskId',
            element: <CheckDetails />
          },
          {
            path: 'safebrowse/:taskId',
            element: <CheckDetails />
          },
          {
            path: 'blacklist/:taskId',
            element: <CheckDetails />
          },
          {
            path: ':taskId',
            element: <CheckDetails />
          },
          {
            path: ':taskId/edit',
            element: <CheckEdit />
          }
        ]
      }
    ]
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
