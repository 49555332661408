import axios from 'axios';
import { Auth } from 'aws-amplify';

const axiosInstance = axios.create(
  {
    //baseURL: 'https://cngpogtqqbgl3d5bnjiha7hf4a.appsync-api.us-east-1.amazonaws.com/'    
  }
);
axiosInstance.interceptors.request.use(
  async (config) => {
    const session = await Auth.currentSession();
    config.headers = {
      // Authorization: `${session.getAccessToken().getJwtToken()}`,
      Authorization: `${session.getIdToken().getJwtToken()}`,
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default axiosInstance;


