import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// TRANSLATIONS
//    common
import common_de from './translations/de/common.json';
import common_en from './translations/en/common.json';
import common_tr from './translations/tr/common.json';

//    overview
import overview_de from './translations/de/overview.json';
import overview_en from './translations/en/overview.json';
import overview_tr from './translations/tr/overview.json';

//    monitor.common
import monitor_common_de from './translations/de/monitor.common.json';
import monitor_common_en from './translations/en/monitor.common.json';
import monitor_common_tr from './translations/tr/monitor.common.json';

//    monitor
import monitor_de from './translations/de/monitor.json';
import monitor_en from './translations/en/monitor.json';
import monitor_tr from './translations/tr/monitor.json';

//    administration
import administration_en from './translations/en/administration.json';


  i18n
  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  //.use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'tr',
    
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      /* format: (value, format, lng) => {
        console.log("[i18n.js] format  value, format, lng", value, format, lng);
        return value; 
      } */
    },
    resources: {
      en: {
        common: common_en,
        overview: overview_en,
        monitor_common: monitor_common_en,
        monitor: monitor_en,
        adm: administration_en
      },
      de: {
        common: common_de,
        overview: overview_de,
        monitor_common: monitor_common_de,
        monitor: monitor_de
        
      },
      tr: {
        common: common_tr,
        overview: overview_tr,
        monitor_common: monitor_common_tr,
        monitor: monitor_tr
      }
    },    
    defaultNS: 'common'
  });

export default i18n;

