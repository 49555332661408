export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const occurencesMap = (array) => {
    
    console.log("occurencesMap input: ", array);
    
    const map = array.reduce((acc, e) => {
        e = e.replace("," , "");
        acc.set(e, (acc.get(e) || 0) + 1);
        return acc;
    }, new Map());
        
    return map;

};

const padWithZeroStr = num => {
    return num < 10 ? '0' + num.toString() : num.toString();
}
export const timeToStr = date => {
    return (
        date.getUTCFullYear().toString() +
        padWithZeroStr(date.getUTCMonth() + 1) +
        padWithZeroStr(date.getUTCDate()) + 
        padWithZeroStr(date.getUTCHours()) +
        padWithZeroStr(date.getUTCMinutes()) 
    );
};

export const str12ToDate = str=> {

    let isoStr = 
        str.substring(0,4) + '-' + //yyyy-
        str.substring(4,6) + '-' + //MM-
        str.substring(6,8) + 'T' + //ddT
        str.substring(8,10) + ':' + //HH:
        str.substring(10,12) + 'Z' // mi

    return new Date(isoStr);
}

